import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tick from "../images/error.png";

function PaymentFaliure() {
  const navigate = useNavigate();
  const [secondsLeft, setSecondsLeft] = useState(5); // Initial countdown value

  useEffect(() => {
    // Decrement the countdown every second
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Redirect to /plans when the countdown reaches zero
    if (secondsLeft === 0) {
      navigate('/plans');
    }

    // Cleanup the interval timer on unmount
    return () => clearInterval(timer);
  }, [secondsLeft, navigate]);

  return (
    <div className="container position-absolute top-50 start-50" style={{ transform: "translate(-50%,-50%)" }}>
      <h3 className='font-weight-bold'>Payment Failed</h3>
      <img src={tick} className='my-4' width={150} alt="tick" />
      <p className='font-weight-light'>Something went wrong in the payment process. Please try again!</p>
      <p>Redirecting in {secondsLeft} second{secondsLeft !== 1 ? 's' : ''}...</p>
    </div>
  );
}

export default PaymentFaliure;
