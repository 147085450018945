import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';

// const useLocalStorage = (key, initialValue) => {
//   const [value, setValue] = useState(() => {
//     const storedValue = localStorage.getItem(key);
//     return storedValue? JSON.parse(storedValue) : initialValue;
//   });

//   useEffect(() => {
//     localStorage.setItem(key, JSON.stringify(value));
//   }, [value, key]);

//   return [value, setValue];
// };

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('isLoggedIn'));

  useEffect(() => {
    if (location.state && location.state.isLoggedIn) {
      setIsLoggedIn(true);
    }
  }, [location]);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('auth_token');
    navigate('/signin');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#07090d' }}>
      <div className="container-fluid">
        <Link className="navbar-brand ms-3" to="/">
          <img src={logo} alt="Logo" width="40" height="30" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className={`nav-link ${location.pathname === '/'? 'active' : ''}`} to="/">
              Home
            </Link>
            <Link className="nav-link" to="/plans">
              Plans
            </Link>
            {isLoggedIn? (
              <Link className="nav-link" onClick={handleLogout}>
                Logout
              </Link>
            ) : (
              <Link className={`nav-link ${location.pathname === '/signin'? 'active' : ''}`} to="/signin">
                Signin
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;