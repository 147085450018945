import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import tick from "../images/green-tick.png";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transactionId = params.get('id'); // Adjusted for consistency
    const userId = params.get('userId'); // Ensure user ID is also passed here
    const selectedPlan = params.get('selectedPlan'); // And the selected plan

    if (transactionId) {
        axios.post(`https://api.astradefend.com/status?id=${transactionId}&userId=${userId}&selectedPlan=${selectedPlan}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log('Payment verified successfully:', response.data);
                } else {
                    console.error('Payment verification failed:', response.data);
                    navigate('/failure');
                }
            })
            .catch((error) => {
                console.error('Error verifying payment status:', error);
                navigate('/failure');
            });
    }
}, [location, navigate]);

  const handleDownloadClick = () => {
    const token = localStorage.getItem('auth_token');

    if (!token) {
      navigate('/signin');
    }
  };

  return (
    <div className="container position-absolute top-50 start-50" style={{ transform: "translate(-50%,-50%)" }}>
      <h3 className='font-weight-bold'>Your Payment has been successfully verified</h3>
      <img src={tick} className='my-4' width={150} alt="tick" />
      <p className='font-weight-light'>Your Dataguard monitor is ready. You can download it from here!</p>
      <button className='btn mx-auto w-auto px-5 py-3 mt-2' onClick={handleDownloadClick}>
        Download <i className="bi bi-download ms-2"></i>
      </button>
    </div>
  );
};

export default PaymentSuccess;
