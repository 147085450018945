import React,{useState} from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import './styles/ChoosePlan.css'; // Import your custom CSS for additional styles

const ChoosePlan = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handlePayment = async (plan) => {
    setLoading(true);
    setError('');
    // Retrieve the auth_token from localStorage
    const token = localStorage.getItem('auth_token');
    if (!token) throw new Error("User not authenticated");

    // Decode the token to extract user information
    const decodedToken = jwtDecode(token);
    // console.log(decodedToken.user.email,'ddd');
    
    const email = decodedToken.user.email; // Extract email from the decoded token
    // console.log(email,'eeeee');
    

    const data = {
        email: email,
        orderId: `ORDER_${Date.now()}`, // Unique order ID (for example, a timestamp)
        amount: plan === 'basic' ? '10' : '20', // Amount based on the chosen plan
        selectedPlan: plan // Send the selected plan
    };

    try {
        const response = await axios.post('https://api.astradefend.com/create-order', data);
        console.log(response.data);
        window.location.href = response.data.url;
    } catch (error) {
        console.log("error in payment", error);
    }
};


  return (
    <div className="container d-flex flex-column align-items-center justify-content-center text-white">
      <h1 className="my-5 text-center">Choose Your Plan</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="row w-100 justify-content-center">

        {/* Basic Plan */}
        <div className="col-md-5 col-lg-4 mb-4">
          <div className="card text-center plan-card">
            <div className="card-header py-4 position-relative">
              <h2 className="card-title">Basic Plan</h2>
              <span className="badge position-absolute start-50 top-0 translate-middle bg-light text-dark">Most Popular</span>
            </div>
            <div className="card-body text-white">
              <h3 className="card-price">$10<span className="text-white">/month</span></h3>
              <p className="card-text">Perfect for individuals.</p>
              <ul className="list-unstyled">
                <li>✔ Feature 1</li>
                <li>✔ Feature 2</li>
                <li>✖ Feature 3</li>
              </ul>
              <button className="btn" onClick={() => handlePayment('basic')} disabled={loading}>
                {loading ? 'Processing...' : 'Choose Basic'}
              </button>
            </div>
          </div>
        </div>

        {/* Premium Plan */}
        <div className="col-md-5 col-lg-4 mb-4">
          <div className="card text-center text-white plan-card">
            <div className="card-header py-4">
              <h2 className="card-title">Premium Plan</h2>
            </div>
            <div className="card-body">
              <h3 className="card-price">$20<span className="text-white">/month</span></h3>
              <p className="card-text">Ideal for teams.</p>
              <ul className="list-unstyled">
                <li>✔ Feature 1</li>
                <li>✔ Feature 2</li>
                <li>✔ Feature 3</li>
              </ul>
              <button className="btn" onClick={() => handlePayment('premium')} disabled={loading}>
                {loading ? 'Processing...' : 'Choose Premium'}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ChoosePlan;