import React from 'react'
import { useNavigate } from 'react-router-dom';

const Proxy = () => {
  const navigate = useNavigate();

  const handleDownloadClick = () => {
    const token = localStorage.getItem('auth_token');

    if (!token) {
      navigate('/signin');
    } else {
      
    }
  };
  return (
    <div className="branding-section bg-#07090d text-light m-4 p-4 p-md-7 d-flex flex-column flex-md-row align-items-center justify-content-between" style={{textAlign:"left"}}>
      <div className="content-section col-md-6 mb-4 mb-md-0">
        <h1 className="display-2 m-4" style={{fontWeight:"700"}}>DataGuard Monitor</h1>
        <p className="lead m-4 fs-4">
        We have identified and solved many business problems related to data loss. Our innovative cloud upload identification tool detects and alerts administrators about potential data breaches. We provide agents and an admin panel for operators or administrators. The agents use a proxy on endpoint machines to monitor and secure data transfers.</p>
        <div style={{width: "40%",marginLeft: "25px",marginTop: "7%"}}>
            <button className='btn' onClick={handleDownloadClick}>Download <i className="bi bi-download ms-2"></i></button>
        </div>
      </div>
      <div className="image-section col-md-6 d-flex justify-content-center">
        <img
          src="https://placehold.co/600x700"
          alt="Placeholder image for branding"
          className="img-fluid rounded"
        />
      </div>
    </div>
)
}

export default Proxy;