import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/Signin.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    company_name: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.astradefend.com/signup', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { token } = response.data;
      localStorage.setItem('auth_token', token);
      // localStorage.setItem('isLoggedIn', true);

      if (response.status === 200) {
        toast.success('Signup successful');
        navigate('/', { state: { isLoggedIn: true } });
      } else {
        toast.error(`Signup failed: ${response.data.message}`);
        // Handle signup error
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("Email already exist");
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-box">
        <h2>Sign Up</h2>
        <form type="POST" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" required onChange={handleChange} value={formData.username} />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required onChange={handleChange} value={formData.password} />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required onChange={handleChange} value={formData.email} />
          </div>
          <div className="input-group">
            <label htmlFor="company_name">Company Name</label>
            <input type="text" id="company_name" name="company_name" required onChange={handleChange} value={formData.company_name} />
          </div>
          <button className='btn' type="submit">Sign Up</button>
        </form>
        <p className="forgot-password">
          Already have an account? <Link to='/signin'>Sign in</Link>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
