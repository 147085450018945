import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Signin from './components/Signin';
import Signup from './components/Signup';
import Proxy from './components/Proxy';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ChoosePlan from './components/plans';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFaliure from './components/PaymentFaliure';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Proxy />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path='/plans' element={<ChoosePlan/>}/>
          <Route path='/payment-success' element={<PaymentSuccess/>}/>
          <Route path='/payment-failure' element={<PaymentFaliure/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
