import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import './styles/Signin.css';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://api.astradefend.com/signin', { email, password });
      const { token } = response.data;

      // Save the token in localStorage
      localStorage.setItem('auth_token', token);
      // localStorage.setItem('isLoggedIn', true);
      toast.success('Login successful!');
      navigate('/', { state: { isLoggedIn: true } });
    } catch (error) {
      console.error('There was an error!', error);
      toast.error('Invalid credentials.');
    }
  };

  return (
    <div className="signin-container">
      <ToastContainer />
      <div className="signin-box">
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required 
            />
          </div>
          <button className='btn' type="submit">Sign In</button>
        </form>
        <p className="forgot-password">
          <a href="#">Forgot your password?</a>
        </p>
        <p className="forgot-password">
            Don't have an account? <Link to='/signup'>Signup</Link>
        </p>
      </div>
    </div>
  );
};

export default Signin;
